import { render, staticRenderFns } from "./ParamRecord.vue?vue&type=template&id=b61880f0&scoped=true&"
import script from "./ParamRecord.vue?vue&type=script&lang=js&"
export * from "./ParamRecord.vue?vue&type=script&lang=js&"
import style0 from "./ParamRecord.vue?vue&type=style&index=0&id=b61880f0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b61880f0",
  null
  
)

export default component.exports